import React, { useEffect } from 'react'
import axios from 'axios'
import '../styles/LeaderBoard.css'


const LeaderBoard = ({ leaderBoardData, setLeaderBoardData}) => {


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://pygames.live/api/pySnake/discord-users');
                setLeaderBoardData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [setLeaderBoardData]);


    return (
        <div className='leaderboard-container'>
            <h2 className='leaderboard-title'>LeaderBoard:</h2>
            <table>
                <thead>
                    <tr>
                        <th>Top 5</th>
                        <th>Name</th>
                        <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                    {leaderBoardData !== null ? (
                        leaderBoardData
                            .sort((user1, user2) => user2.score - user1.score) // Sort by score in descending order
                            .slice(0, 5) // Get the top 5 entries
                            .map((user, index) => (
                                <tr key={index + 1}>
                                    <td>{index + 1}.</td>
                                    <td>{user.discord_name}</td>
                                    <td>{user.score}</td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td colSpan="3">Loading...</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default LeaderBoard