import React, { useEffect } from 'react'
import '../styles/SnakeCustomization.css'
import axios from 'axios';

const SnakeCustomization = ({isUserInfoFetched, discordId, snakeColor, setSnakeColor, highestScore}) => {

    const handleColorSwitch = (number) => {
        setSnakeColor(number);
    }

    useEffect (()=> {
        if (isUserInfoFetched) {
            axios.patch(`api/pySnake/update-discord-user-color/${discordId}`, {
                pySnakeColor: snakeColor
            })
            .then(() => {
                
            })
            .catch((err)=>console.error(err));
        }
    }, [snakeColor, discordId, isUserInfoFetched])

    return (
        <div className='snake-customization-container'>
            {/* <div className="hide">Not Available</div> */}
            <div className="snakeC-box">
                {highestScore < 10 && (<div className="locked-content"></div>)}
                <span className='snakeC-requiredScore'>10</span>
                <div className="snakeC-showcase"><div className="first-skin" /></div>
                <button
                    className="snakeC-equip-button"
                    onClick={() => {handleColorSwitch(1);}}
                    disabled={highestScore < 10}
                >Equip</button>
            </div>
            <div className="snakeC-box">
            {highestScore < 25 && (<div className="locked-content"></div>)}
                <span className='snakeC-requiredScore'>25</span>
                <div className="snakeC-showcase"><div className="second-skin" /></div>
                <button
                    className="snakeC-equip-button"
                    onClick={() => {handleColorSwitch(2);}}
                    disabled={highestScore < 25}
                >Equip</button>
            </div>
            <div className="snakeC-box">
                {highestScore < 50 && (<div className="locked-content"></div>)}
                <span className='snakeC-requiredScore'>50</span>
                <div className="snakeC-showcase"><div className="third-skin" /></div>
                <button
                    className="snakeC-equip-button"
                    onClick={() => {handleColorSwitch(3);}}
                    disabled={highestScore < 50}
                >Equip</button>
            </div>
            <div className="snakeC-box">
                {highestScore < 75 && (<div className="locked-content"></div>)}
                <span className='snakeC-requiredScore'>75</span>
                <div className="snakeC-showcase"><div className="forth-skin" /></div>
                <button
                    className="snakeC-equip-button"
                    onClick={() => {handleColorSwitch(4);}}
                    disabled={highestScore < 75}
                >Equip</button>
            </div>
            <div className="snakeC-box">
                {highestScore < 100 && (<div className="locked-content"></div>)}
                <span className='snakeC-requiredScore'>100</span>
                <div className="snakeC-showcase"><div className="fifth-skin" /></div>
                <button
                    className="snakeC-equip-button"
                    onClick={() => {handleColorSwitch(5);}}
                    disabled={highestScore < 100}
                >Equip</button>
            </div>
            <div className="snakeC-box">
                {highestScore < 200 && (<div className="locked-content"></div>)}
                <span className='snakeC-requiredScore'>200</span>
                <div className="snakeC-showcase"><div className="sixth-skin" /></div>
                <button
                    className="snakeC-equip-button"
                    onClick={() => {handleColorSwitch(6);}}
                    disabled={highestScore < 200}
                >Equip</button>
            </div>
            <div className="snakeC-box">
                {highestScore < 300 && (<div className="locked-content"></div>)}
                <span className='snakeC-requiredScore'>300</span>
                <div className="snakeC-showcase"><div className="seventh-skin" /></div>
                <button
                    className="snakeC-equip-button"
                    onClick={() => {handleColorSwitch(7);}}
                    disabled={highestScore < 300}
                >Equip</button>
            </div>
        </div>
    )
}

export default SnakeCustomization