import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import discordAuth from '../configs/DiscordAuthorization';

const useDiscordLogin = () => {
    const navigate = useNavigate();

    const handleDiscordLogin = () => {
        window.location.href = discordAuth;
    };

    const fetchUserInfo = (code, isUserInfoFetched, setDiscordId, setDiscordUsername, setIsUserInfoFetched, setAvatarUrl) => {
        if (code && !isUserInfoFetched) {
            const clientId = '1200261235250307082';
            const clientSecret = 'DnbrigdYtMCsCCSB8oDfaDbBpT75YZmx';
            const redirectUri = 'https://pygames.live/';
            const discordTokenUrl = 'https://discord.com/api/oauth2/token';
    
            const params = new URLSearchParams();
            params.append('client_id', clientId);
            params.append('client_secret', clientSecret);
            params.append('grant_type', 'authorization_code');
            params.append('code', code);
            params.append('redirect_uri', redirectUri);
            params.append('scope', 'identify');
    
            axios.post(discordTokenUrl, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                const accessToken = response.data.access_token;
                const discordUserUrl = 'https://discord.com/api/users/@me';
    
                axios.get(discordUserUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                .then(userResponse => {
                    console.log(userResponse)
                    const avatarString = userResponse.data.avatar;
                    const avatarURL = `https://cdn.discordapp.com/avatars/${userResponse.data.id}/${avatarString}`;
                    setAvatarUrl(avatarURL);
                    setDiscordId(userResponse.data.id);
                    setDiscordUsername(userResponse.data.global_name);
                    setIsUserInfoFetched(true);
                    navigate('/');
                })
                .catch(error => {
                    // Handle error in user info request
                    console.error('Error fetching user info:', error);
                });
            })
            .catch(error => {
                // Handle error in access token request
                console.error('Error fetching access token:', error);
            });
        }
    };
    
    

    return { handleDiscordLogin, fetchUserInfo };
};

export default useDiscordLogin;