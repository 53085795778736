import React, { useState, useEffect } from 'react'
import SnakeGameBoard from '../components/SnakeGameBoard'
import LeaderBoard from '../components/LeaderBoard'
import SnakeCustomization from '../components/SnakeCustomization';
import axios from 'axios'

const SnakeGame = ({pySnakeSelection, discordId, isUserInfoFetched, discordUsername, leaderBoardData, setLeaderBoardData, gameOver, setGameOver, gameStart, setGameStart, score, setScore, highestScore, setHighestScore, snakeColor, setSnakeColor}) => {

    const updateLeaderboard = async () => {
        try {
            const response = await axios.get('https://pygames.live/api/pySnake/discord-users');
            setLeaderBoardData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div className="snake-game-container">
            {pySnakeSelection ? (
                <div className='snake-game-container'>
                    <div className="snake-game-header">
                        <h1>Snake Game</h1>
                        <div className="subheader-2">
                            <h2>Score: {score}</h2>
                            <h4>Your High Score: {highestScore}</h4>
                        </div>
                    </div>
                    <div className="game-container">
                        {gameStart ? (
                            <div className='container-a'>
                                {gameOver ? (
                                    <div className="game-over-container">
                                        <h4 className='game-over-text'>Game Over!</h4>
                                        {highestScore === score ? (
                                            <h4 className="game-over-text">You have beaten your previous score!</h4>
                                        ) : null}
                                        {highestScore > score ? (
                                            <h4 className='game-over-text'>You have not beaten your previous score!</h4>
                                        ) : null}
                                    </div>
                                ) : null}
                                <button className={`${'pysnake-button'} ${'pysnake-button-end'}`} onClick={() => { setGameStart(false); setScore(0); setGameOver(false); }}>End Game</button>
                            </div>
                        ) : (
                            <button className={`${'pysnake-button'} ${'pysnake-button-start'}`} onClick={() => { setGameStart(true); setScore(0); }}>Start Game</button>
                        )}
                        {gameStart ? (
                            <SnakeGameBoard
                                score={score}
                                setScore={setScore}
                                discordId={discordId}
                                updateLeaderboard={updateLeaderboard}
                                gameOver={gameOver}
                                setGameOver={setGameOver}
                                isUserInfoFetched={isUserInfoFetched}
                                highestScore={highestScore}
                                setHighestScore={setHighestScore}
                                discordUsername={discordUsername}
                                snakeColor={snakeColor}
                            />
                        ) : null}
                        <SnakeCustomization
                            snakeColor={snakeColor}
                            setSnakeColor={setSnakeColor}
                            isUserInfoFetched={isUserInfoFetched}
                            discordId={discordId}
                            highestScore={highestScore}
                        />
                        <LeaderBoard
                            discordId={discordId}
                            leaderBoardData={leaderBoardData}
                            setLeaderBoardData={setLeaderBoardData}
                        />
                    </div>
                </div>

            ) : null}
        </div>
    )
}

export default SnakeGame