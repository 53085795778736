import React, { useState, useEffect } from 'react';
import { UP, DOWN, LEFT, RIGHT, useSwipeable } from 'react-swipeable';
import '../styles/SnakeGame.css'
import TigerSnakeBody from '../styles/icons/TigerSkin.png'
import ZebraSnakeBody from '../styles/icons/pysnake-zebra-skin.png'
import Snake1 from '../styles/icons/pysnake-snake1.png'
import axios from 'axios';

const SnakeGameBoard = ({ score, setScore, discordId, updateLeaderboard, gameOver, setGameOver, isUserInfoFetched, highestScore, setHighestScore, discordUsername, snakeColor }) => {

    const [onMobile, setOnMobile] = useState(false);

    const rowLength = 30;
    const colLength = 30;

    const generateRandomPosition = (currentFoodPosition) => {
        let newPosition;
        do {
            newPosition = {
                row: Math.floor(Math.random() * rowLength),
                col: Math.floor(Math.random() * colLength)
            };
        } while (
            currentFoodPosition &&
            newPosition.row === currentFoodPosition.row &&
            newPosition.col === currentFoodPosition.col
        );

        return newPosition;
    };

    let snakeSpeed = 50;
    if (window.innerWidth <= 768 && onMobile) {
        snakeSpeed = 90;
    }

    const snakeStyle = (rowIndex, colIndex) => {
        const isSnakeSegment = snake.find(pos => pos.row === rowIndex && pos.col === colIndex);
        if (!isSnakeSegment) return {};
        switch (snakeColor) {
            case 1:
                return { backgroundColor: 'black' }
            case 2:
                const animationDelay = -snake.findIndex(pos => pos.row === rowIndex && pos.col === colIndex) * 0.2 + 's';
                return {
                    animation: 'color-shift 2s linear infinite',
                    animationDelay: animationDelay
                };
            case 3:
                return { background: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)' }
            case 4:
                return {
                    backgroundImage: `url(${TigerSnakeBody})`,
                }
            case 4.1:
                return {
                    backgroundImage: `url(${ZebraSnakeBody})`,
                }
            case 5:
                return {
                    backgroundColor: 'rgb(0,0,0)',
                    backgroundImage: `url(https://www.zingerbugimages.com/backgrounds/marble.jpg)`,
                    backgroundPosition: 'top left',
                    backgroundRepeat: 'repeat',
                    backgroundAttachment: 'fixed',
                };
            case 6:
                return {
                    backgroundColor: 'gold',
                    border: '2px solid darkgoldenrod', // Border color to enhance visibility
                    boxShadow: '0 0 10px gold' // Adding a subtle glow effect
                }
            case 7:
                return {
                    background: `
                        linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%),
                        linear-gradient(-45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%),
                        linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%),
                        linear-gradient(-45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%)
                    `,
                    backgroundSize: '20px 20px',
                    backgroundColor: 'gold',
                    border: '2px solid darkgoldenrod',
                    boxShadow: '0 0 10px gold'
                }
            case 8:
                return {
                    backgroundImage: `url(${Snake1})`,
                    backgroundPosition: 'center'
                }
            default:
                return { backgroundColor: 'blue' };
        }
    };

    const [board, setBoard] = useState([]);
    const [snake, setSnake] = useState([{ row: 0, col: 0 }]);
    const [food, setFood] = useState(generateRandomPosition());
    const [direction, setDirection] = useState('RIGHT');

    useEffect(() => {
        const handleKeyPress = (e) => {
            switch (e.key) {
                case "ArrowUp":
                    e.preventDefault();
                    if (direction !== "DOWN") {
                        setDirection("UP");
                        setOnMobile(false);
                    }
                    break;
                case "ArrowDown":
                    e.preventDefault();
                    if (direction !== "UP") {
                        setDirection("DOWN");
                        setOnMobile(false);
                    }
                    break;
                case "ArrowLeft":
                    e.preventDefault();
                    if (direction !== "RIGHT") {
                        setDirection("LEFT");
                        setOnMobile(false);
                    }
                    break;
                case "ArrowRight":
                    e.preventDefault();
                    if (direction !== "LEFT") {
                        setDirection("RIGHT");
                        setOnMobile(false);
                    }
                    break;
                default:
                    break;
            }
        };
        document.addEventListener('keydown', handleKeyPress);

        const moveSnake = () => {
            if (gameOver) {
                return;
            }

            const newSnake = [...snake];
            // Determine the snake's heads new position based on the direction
            let newHead;
            switch (direction) {
                case 'UP':
                    newHead = { row: snake[0].row - 1, col: newSnake[0].col };
                    break;
                case 'DOWN':
                    newHead = { row: snake[0].row + 1, col: newSnake[0].col };
                    break;
                case 'LEFT':
                    newHead = { row: snake[0].row, col: newSnake[0].col - 1 };
                    break;
                case 'RIGHT':
                    newHead = { row: snake[0].row, col: newSnake[0].col + 1 };
                    break;
                default:
                    break;
            }

            // Check for collision with the food or itself
            if (
                food.row === newHead.row &&
                food.col === newHead.col &&
                !gameOver
            ) {
                setScore(prevScore => prevScore + 1);
                setFood(generateRandomPosition());
            } else if (
                newSnake.some(
                    (pos) => pos.row === newHead.row && pos.col === newHead.col
                )
            ) {
                setGameOver(true);
                clearInterval(intervalId);
            } else {
                // Remove the tail of the snake if it hasn't eaten the food
                newSnake.pop();
            }

            // Check for collision with board edges
            if (
                newHead.row < 0 ||
                newHead.row >= rowLength ||
                newHead.col < 0 ||
                newHead.col >= colLength
            ) {
                setGameOver(true);
                clearInterval(intervalId);
            }

            newSnake.unshift(newHead);

            // Update the state with the new snake position
            setSnake(newSnake);
        };

        // Set up a timer to move the snake periodically
        const intervalId = setInterval(() => {
            moveSnake();
        }, snakeSpeed);

        // Generate the initial game board
        const initialBoard = Array.from({ length: colLength }, () =>
            Array.from({ length: colLength }, () => 0)
        );
        setBoard(initialBoard);

        if (gameOver) {
            if (score > highestScore) {
                setHighestScore(score);
            }
        }

        if (isUserInfoFetched) {
            if (gameOver && score > highestScore) {
                clearInterval(intervalId)
                axios.post('https://pygames.live/api/pySnake/discord-users', {
                    discord_id: discordId,
                    discord_name: discordUsername,
                    score: score
                })
                    .then((response) => {
                        updateLeaderboard();
                    })
                    .catch((error) => console.log(error));
            }
        }

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            clearInterval(intervalId);
        };
    }, [snake, food, direction, gameOver, discordId, discordUsername, highestScore, isUserInfoFetched, score, setGameOver, setHighestScore, setScore, updateLeaderboard, snakeColor, snakeSpeed]);

    const handleSwipe = (swipeDirection) => {
        switch (swipeDirection) {
            case UP:
                if (direction !== "DOWN") {
                    setDirection("UP");
                    setOnMobile(true);
                }
                break;
            case DOWN:
                if (direction !== "UP") {
                    setDirection("DOWN");
                    setOnMobile(true);
                }
                break;
            case LEFT:
                if (direction !== "RIGHT") {
                    setDirection("LEFT");
                    setOnMobile(true);
                }
                break;
            case RIGHT:
                if (direction !== "LEFT") {
                    setDirection("RIGHT");
                    setOnMobile(true);
                }
                break;
            default:
                break;
        }
    };

    const handlers = useSwipeable({
        onSwipedUp: () => handleSwipe(UP),
        onSwipedDown: () => handleSwipe(DOWN),
        onSwipedLeft: () => handleSwipe(LEFT),
        onSwipedRight: () => handleSwipe(RIGHT),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div className="content-box">
            <div {...handlers} className='game-board-container'>
                {board.map((row, rowIndex) => (
                    row.map((cell, colIndex) => (
                        <div
                            key={`${rowIndex}-${colIndex}`}
                            className={`${snake.find(pos => pos.row === rowIndex && pos.col === colIndex)
                                ? 'snake'
                                : ''
                                } ${food.row === rowIndex && food.col === colIndex ? 'food' : ''
                                }`}
                            style={snakeStyle(rowIndex, colIndex)}
                        />
                    ))
                ))}
            </div>
        </div>
    );
};

export default SnakeGameBoard;










// STORE SCORE IN LOCAL FILES