import React, { useState, useEffect } from 'react'
import '../styles/Main.css'
import PyGamesWhiteLogo from '../styles/icons/pygames-logo-white.png';
import useDiscordLogin from '../scripts/DiscordLogin'
import axios from 'axios'
import SnakeGame from '../components/SnakeGame';

const Main = () => {
    const [pySnakeSelection, setPySnakeSelection] = useState(false);
    const [leaderBoardData, setLeaderBoardData] = React.useState(null);
    const [gameOver, setGameOver] = useState(false);
    const [gameStart, setGameStart] = useState(false);
    const [score, setScore] = useState(0);
    const [highestScore, setHighestScore] = useState(0);
    const [snakeColor, setSnakeColor] = useState(0);


    const [isUserInfoFetched, setIsUserInfoFetched] = useState(false);
    const [fetchAttempted, setFetchAttempted] = useState(false);
    const [discordId, setDiscordId] = useState(null);
    const [discordUsername, setDiscordUsername] = useState(null);
    const [avatarUrl, setAvatarUrl] = useState("");

    const { handleDiscordLogin, fetchUserInfo } = useDiscordLogin();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code && !isUserInfoFetched && !fetchAttempted) {
            fetchUserInfo(code, isUserInfoFetched, setDiscordId, setDiscordUsername, setIsUserInfoFetched, setAvatarUrl);
            setFetchAttempted(true);
        }
    }, [isUserInfoFetched, fetchUserInfo, fetchAttempted]);

    useEffect(() => {
        if (isUserInfoFetched && discordId && discordUsername) {
            axios.get(`https://pygames.live/api/pySnake/discord-user/${discordId}`)
                .then((response) => {

                    setHighestScore(response.data.score)
                    setSnakeColor(response.data.pySnakeColor)

                    const currentDBUsername = response.data.discord_name
                    if (currentDBUsername !== discordUsername) {
                        axios.post('https://pygames.live/api/pySnake/discord-users', {
                            discord_id: discordId,
                            discord_name: discordUsername
                        })
                            .then((response) => {

                            })
                            .catch((error) => {
                                console.error('Error updating Discord name:', error);
                            });
                    }
                    return null;
                })
                .catch((error) => console.log(error));
        }
    }, [isUserInfoFetched, discordId, discordUsername]);

    return (
        <div className='main-container'>
            <div className="space-background"></div>
            <div className='shooting-star'></div>
            <img className='pygames-logo-white' src={PyGamesWhiteLogo} alt="PyGames Logo" />
            {!pySnakeSelection ? (
                <div className="game-selection-container">
                    <h1>Select your game</h1>
                    <div className="game-selection-buttons">
                        <button className='pysnake-icon' onClick={() => { setPySnakeSelection(!pySnakeSelection); setGameStart(false); }}></button>
                        <button>PyTetris(Not available)</button>
                        <button>PyCheckers(Not available)</button>
                        <button>PyChess(Not available)</button>
                        <button className='ghost'></button>
                        <button className='ghost'></button>
                    </div>
                </div>
            ) : (
                <div className="back-button-container">
                    <button className='button' onClick={() => { setPySnakeSelection(false); setGameStart(false); setGameOver(false); setScore(0); }}>Back To Game Selection</button>
                    <div className="space">
                        <span style={{ '--i': 31 }} className="star"></span>
                        <span style={{ '--i': 12 }} className="star"></span>
                        <span style={{ '--i': 57 }} className="star"></span>
                        <span style={{ '--i': 93 }} className="star"></span>
                        <span style={{ '--i': 23 }} className="star"></span>
                        <span style={{ '--i': 70 }} className="star"></span>
                        <span style={{ '--i': 6 }} className="star"></span>
                    </div>
                </div>
            )}
            {!isUserInfoFetched ? (
                <div className="login-container">
                    <h2>Login to see & save your stats</h2>
                    <button className='discord-login-button' onClick={handleDiscordLogin}>
                        <svg viewBox="0 0 16 16" className="bi bi-discord" fill="currentColor" height="16" width="16" xmlns="https://www.w3.org/2000/svg">
                            <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"></path>
                        </svg>
                        <span>Discord</span>
                    </button>
                </div>
            ) : (
                <h3 className='discord-name'><img src={avatarUrl} alt="avatar" className='discord-avatar' />Welcome {discordUsername}</h3>
            )}
            <SnakeGame
                pySnakeSelection={pySnakeSelection}
                discordId={discordId}
                isUserInfoFetched={isUserInfoFetched}
                discordUsername={discordUsername}
                leaderBoardData={leaderBoardData}
                setLeaderBoardData={setLeaderBoardData}
                gameOver={gameOver}
                setGameOver={setGameOver}
                gameStart={gameStart}
                setGameStart={setGameStart}
                score={score}
                setScore={setScore}
                highestScore={highestScore}
                setHighestScore={setHighestScore}
                snakeColor={snakeColor}
                setSnakeColor={setSnakeColor}
            />
        </div>
    );
}

export default Main